.registrationWrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
}
.registrationBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 85vh;
  background-image: url('');
  background-size: cover;
}
.registrationContent {
  text-align: left;
  min-height: 200px;
  width: 568px;
  margin: 30px auto;
  background: white;
  border-radius: 10px;
  padding: 32px;
  box-shadow: 0px 4px 6px #00000029;
}
.registrationHeader {
  font-size: 32px;
  font-weight: bold;
}
.registrationSubHeader {
  font-size: 10px;
}
.registrationContent p {
  font-size: 15px;
}
.registrationForm {
  flex-direction: column;
  padding: 15px;
}
.registrationInputs {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.registrationSingleInput {
  width: 100%;
  font-size: 10px;
  display: flex;
  flex-direction: column;
}
.registrationSingleInput label {
  pointer-events: none;
  position: relative;
  line-height: 14px;
  top: 31px;
  font-size: 14px;
  transition: all 0.15s;
}
.registrationSingleInput:focus-within > label,
.registrationSingleInput.active > label {
  top: 20px;
  font-size: 10px;
}
.registrationInputs input {
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #c7c7c7;
  padding: 22px 16px 6px;
}
.errored input {
  border: 1px solid #e01218;
  background: #f6edec;
}
.registrationInputs input:hover {
  border: 1px solid #434343;
}
.registrationInputs input:focus {
  border: 1px solid #434343;
  outline: none;
}
.errors {
  padding: 5px 0px;
  color: #e01218;
  font-size: 12px;
}

.inputError {
  padding: 2px 0px;
  color: #e01218;
  font-size: 10px;
}
.registrationLoading {
  padding: 10px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .registrationContentWrapper {
    max-width: 100%;
  }
  .registrationContent {
    width: 100%;
    border-radius: 0px;
    padding: 0px;
  }
  .registrationInputs {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .registrationSingleInput {
    width: 100%;
    margin-bottom: 15px;
  }
  .registrationText {
    padding: 15px;
  }
}
