.home {
  display: flex;
  flex-direction: row; 
  align-content: center;
  justify-content: right;
  /*height: 100%;*/
  width: 100%;
  padding: 10px;
}

